export const parseOptionalNumber = (x: any): number | undefined => {
  const val = Number(x);
  if (x === "" || x === undefined || x === null || Number.isNaN(val)) return undefined;
  return val;
};

export const parseOptionalString = (x: any): string | undefined => {
  if (x === "" || x === undefined || x === null) return undefined;
  return x;
};
