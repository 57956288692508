import { Button, Divider, Grid, TextField } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers-pro";
import { AxiosResponse } from "axios";
import { Dayjs } from "dayjs";
import { FC, useState } from "react";
import useDebugStationAction from "src/hooks/debug/useDebugStationAction";
import { notifyErrorRaw } from "../notifications";
import { parseOptionalNumber, parseOptionalString } from "../parse-utilities";

const ReserveConnector: FC<{ stationId: string }> = ({ stationId }) => {
  const [connectorId, setConnectorId] = useState<number>(1);
  const [expiryDate, setExpiryDate] = useState<Dayjs | undefined>();
  const [idTag, setIdTag] = useState("");
  const [parentIdTag, setParentIdTag] = useState<string | undefined>();
  const [reservationId, setReservationId] = useState<number | undefined>();

  const { mutate } = useDebugStationAction(stationId);

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            if (stationId)
              mutate(
                {
                  method: "POST",
                  url: "reserve",
                  body: {
                    connector_id: connectorId,
                    expiry_date: expiryDate,
                    id_tag: idTag,
                    parent_id_tag: parentIdTag,
                  },
                },
                {
                  onSuccess: (data: AxiosResponse<any>) => setReservationId(data.data.reservation_id),
                },
              );
            else notifyErrorRaw("Station ID is required");
          }}
        >
          <Grid container alignItems="center" spacing={1}>
            <Grid item>
              <TextField
                label="connectorId"
                size="small"
                required
                value={connectorId}
                onChange={(event) => {
                  const valueToSet = parseOptionalNumber(event.target.value);
                  if (valueToSet !== undefined) {
                    setConnectorId(valueToSet);
                  }
                }}
              />
            </Grid>

            <Grid item>
              <DateTimePicker
                label="expiryDate"
                views={["year", "month", "day", "hours", "minutes", "seconds"]}
                slotProps={{ textField: { size: "small" } }}
                onChange={(value) => setExpiryDate(value as Dayjs)}
              />
            </Grid>

            <Grid item>
              <TextField label="idTag" size="small" required onChange={(event) => setIdTag(event.target.value)} />
            </Grid>

            <Grid item>
              <TextField
                label="parentIdTag"
                size="small"
                onChange={(event) => setParentIdTag(parseOptionalString(event.target.value))}
              />
            </Grid>

            <Grid item>
              <Button variant="contained" type="submit">
                RESERVE CONNECTOR
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>

      <Grid item>
        <Divider />
      </Grid>

      <Grid item>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            if (stationId)
              mutate({
                method: "POST",
                url: "cancel_reservation",
                body: { reservation_id: reservationId },
              });
            else notifyErrorRaw("Station ID is required");
          }}
        >
          <Grid container alignItems="center" spacing={1}>
            <Grid item>
              <TextField
                label="reservationId"
                size="small"
                required
                value={reservationId ?? ""}
                onChange={(event) => {
                  const valueToSet = parseOptionalNumber(event.target.value);
                  if (valueToSet !== undefined) {
                    setReservationId(valueToSet);
                  }
                }}
              />
            </Grid>

            <Grid item>
              <Button variant="contained" type="submit">
                CANCEL RESERVATION
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default ReserveConnector;
