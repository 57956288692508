import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers-pro";
import { Dayjs } from "dayjs";
import { FC, useState } from "react";
import useDebugStationAction from "src/hooks/debug/useDebugStationAction";
import { notifyErrorRaw } from "../notifications";
import { parseOptionalNumber } from "../parse-utilities";

const logTypes = ["diagnostics_log", "security_log"];

const GetLog: FC<{ stationId: string }> = ({ stationId }) => {
  const [url, setUrl] = useState<string>("");
  const [logType, setLogType] = useState<string>("");
  const [from, setFrom] = useState<Dayjs | undefined>();
  const [to, setTo] = useState<Dayjs | undefined>();
  const [retries, setRetries] = useState<number | undefined>();
  const [retryDelay, setRetryDelay] = useState<number | undefined>();
  const { mutate } = useDebugStationAction(stationId);

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        if (stationId)
          mutate({
            method: "POST",
            url: "get_log",
            body: {
              url,
              log_type: logType,
              from,
              to,
              retries,
              retry_delay: retryDelay === undefined || retryDelay === null ? undefined : `${retryDelay}s`,
            },
          });
        else notifyErrorRaw("Station ID is required");
      }}
    >
      <Grid container alignItems="center" spacing={1}>
        <Grid item>
          <TextField label="url" size="small" required onChange={(event) => setUrl(event.target.value)} />
        </Grid>

        <Grid item xs>
          <FormControl size="small" fullWidth required>
            <InputLabel>messageType</InputLabel>
            <Select label="logTypes" value={logType} onChange={(event) => setLogType(event.target.value)}>
              {logTypes.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item>
          <DateTimePicker
            label="from"
            views={["year", "month", "day", "hours", "minutes", "seconds"]}
            slotProps={{ textField: { size: "small" } }}
            onChange={(value) => setFrom(value as Dayjs)}
          />
        </Grid>

        <Grid item>
          <DateTimePicker
            label="to"
            views={["year", "month", "day", "hours", "minutes", "seconds"]}
            slotProps={{ textField: { size: "small" } }}
            onChange={(value) => setTo(value as Dayjs)}
          />
        </Grid>

        <Grid item>
          <TextField
            label="retries"
            size="small"
            onChange={(event) => setRetries(parseOptionalNumber(event.target.value))}
          />
        </Grid>

        <Grid item>
          <TextField
            label="retryDelay"
            size="small"
            onChange={(event) => setRetryDelay(parseOptionalNumber(event.target.value))}
          />
        </Grid>

        <Grid item>
          <Button variant="contained" type="submit">
            GET LOGS
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default GetLog;
