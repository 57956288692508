import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { FC, useState } from "react";
import useDebugStationAction from "src/hooks/debug/useDebugStationAction";
import { notifyErrorRaw } from "../notifications";
import { parseOptionalNumber } from "../parse-utilities";

const chargingProfilePurposes = ["Any", "ChargePointMaxProfile", "TxDefaultProfile", "TxProfile"];

const ClearChargingProfile: FC<{ stationId: string }> = ({ stationId }) => {
  const [id, setId] = useState<number | undefined>();
  const [connectorId, setConnectorId] = useState<number | undefined>();
  const [chargingProfilePurpose, setChargingProfilePurpose] = useState<string | undefined>();
  const [stackLevel, setStackLevel] = useState<number | undefined>();

  const { mutate } = useDebugStationAction(stationId);
  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        if (stationId)
          mutate({
            method: "POST",
            url: "custom",
            body: {
              request_type: "ClearChargingProfile",
              request_payload: { id, connectorId, chargingProfilePurpose, stackLevel },
            },
          });
        else notifyErrorRaw("Station ID is required");
      }}
    >
      <Grid container alignItems="center" spacing={1}>
        <Grid item>
          <TextField
            label="id"
            size="small"
            onChange={(event) => setId(parseOptionalNumber(event.target.value))}
          />
        </Grid>

        <Grid item>
          <TextField
            label="connectorId"
            size="small"
            onChange={(event) => setConnectorId(parseOptionalNumber(event.target.value))}
          />
        </Grid>

        <Grid item>
          <TextField
            label="stackLevel"
            size="small"
            onChange={(event) => setStackLevel(parseOptionalNumber(event.target.value))}
          />
        </Grid>

        <Grid item xs={4}>
          <FormControl size="small" fullWidth>
            <InputLabel>chargingProfilePurpose</InputLabel>
            <Select
              label="chargingProfilePurpose"
              value={chargingProfilePurpose}
              onChange={(event) =>
                setChargingProfilePurpose(event.target.value === "Any" ? undefined : event.target.value)
              }
            >
              {chargingProfilePurposes.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item>
          <Button variant="contained" type="submit">
            CLEAR CHARGING PROFILE
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default ClearChargingProfile;
