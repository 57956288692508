import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Switch from "@mui/material/Switch";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { FC, useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import { getTariff, upsertTariff } from "src/api/tariffs";
import { TaxDefinition } from "src/api/tariffs/interfaces";
import { currencyCodes, currencyCodesMap } from "src/assets/data/currencyCodes";
import ElementBox from "src/components/ElementBox";
import UserContext from "src/components/UserContext";
import EntityEditForm from "src/components/forms/EntityEditForm";
import Page from "src/components/layout/Page";
import { notifyAxiosError } from "src/components/notifications";
import ParkingException from "src/components/tariffs/ParkingException";
import { TariffFormValues, initialValues, toRequest, validationSchema } from "src/pages/TariffEdit/schema";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

enum TariffElement {
  VAT = "VAT",
  ActivationPrice = "Activation Price",
  KwhBasedTariff = "Kwh Based Tariff",
  TimeBasedTariff = "Time Based Tariff",
  IdlingFee = "Idling Fee",
}

const tariffElementOptions = [
  { label: "VAT", value: TariffElement.VAT },
  { label: "Activation Price", value: TariffElement.ActivationPrice },
  { label: "Kwh Based Tariff", value: TariffElement.KwhBasedTariff },
  { label: "Time Based Tariff", value: TariffElement.TimeBasedTariff },
  { label: "Idling Fee", value: TariffElement.IdlingFee },
];

// TODO i18n label
const timeBlockOptions = [
  { id: 1, label: "1 min" },
  { id: 5, label: "5 mins" },
  { id: 10, label: "10 mins" },
  { id: 15, label: "15 mins" },
  { id: 30, label: "30 mins" },
  { id: 60, label: "60 mins" },
];

export const timeBlockOptionsMap = new Map(
  timeBlockOptions.map((i): [number, { id: number; label: string }] => [i.id, i]),
);

const toDayjs = (time: string) => dayjs(time, "HH:mm");

const TariffEdit: FC = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const pageParams = useParams();
  const { action, id } = pageParams;
  const actions = ["new", "edit"];

  const user = useContext(UserContext);

  const [editFormProps, setEditFormProps] = useState<TariffFormValues>(initialValues);
  const [selectedTariffElements, setSelectedTariffElements] = useState<TariffElement[]>([]);

  const goToListPage = () => {
    navigate("/tariffs");
  };

  const goToTariffPage = (tariffId: string) => {
    navigate(`/tariffs/${tariffId}`);
  };

  useEffect(() => {
    // need to do navigate statements in useEffect, as useEffect must not be conditionally executed
    if (action && !actions.includes(action)) {
      goToListPage();
      return;
    }

    // can't edit something without an ID
    if (action === "edit") {
      if (id === undefined) {
        goToListPage();
        return;
      }
      getTariff(id)
        .then((response) => {
          setEditFormProps({
            ...response.data,
            parkingExceptionPeriods: response.data.parkingExceptionPeriods.map((el) => ({
              from: toDayjs(el.from),
              to: toDayjs(el.to),
            })),
            tariffId: response.data.id,
          });
        })
        .catch((err) => {
          goToListPage();
          notifyAxiosError(err, intl);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pageTitle = intl.formatMessage({ id: "editTariff" });
  const onClose = action === "edit" ? goToTariffPage : goToListPage;

  const onSubmit = async (params: TariffFormValues) => {
    const parsedParams = validationSchema.cast(params);
    const {
      taxPercentage,
      sessionFee,
      kwhFee,
      chargingHourFee,
      parkingHourFee,
      parkingGracePeriodMin,
      parkingHourFeeLimit,
    } = parsedParams;

    const {
      tariffId,
      name,
      description,
      currency,
      taxDefinition,
      chargingHourStepSize,
      parkingHourStepSize,
      parkingExceptionPeriods,
    } = params;

    upsertTariff({
      id: tariffId,
      tenantId: user.activeTenantId,
      name,
      description,
      currency,
      taxPercentage: taxPercentage ?? 0,
      taxDefinition,
      sessionFee: sessionFee ?? 0,
      kwhFee: kwhFee ?? 0,
      chargingHourFee: chargingHourFee ?? 0,
      chargingHourStepSize,
      parkingHourFee: parkingHourFee ?? 0,
      parkingHourStepSize,
      parkingGracePeriodMin: parkingGracePeriodMin ?? 0,
      parkingHourFeeLimit: parkingHourFeeLimit ?? 0,
      parkingExceptionPeriods: toRequest(parkingExceptionPeriods),
    })
      .then((response) => {
        goToTariffPage(response.data.id);
      })
      .catch((err) => notifyAxiosError(err, intl));
  };

  const formik = useFormik({
    initialValues: editFormProps,
    enableReinitialize: true,
    validationSchema,
    onSubmit,
  });

  // Set values to 0 when checkbox is deselected + Update selectedTariffElements State
  const setTariffElemField = (
    newValue: {
      label: string;
      value: TariffElement;
    }[],
  ) => {
    if (
      !selectedTariffElements.includes(TariffElement.VAT) &&
      newValue.find((element) => element.value === TariffElement.VAT)
    ) {
      formik.setFieldValue("taxDefinition", TaxDefinition.VAT_EXCLUDED);
    }
    if (
      selectedTariffElements.includes(TariffElement.VAT) &&
      !newValue.find((element) => element.value === TariffElement.VAT)
    ) {
      formik.setFieldValue("taxPercentage", 0);
      formik.setFieldValue("taxDefinition", TaxDefinition.VAT_UNDEFINED);
    }
    if (
      selectedTariffElements.includes(TariffElement.ActivationPrice) &&
      !newValue.find((element) => element.value === TariffElement.ActivationPrice)
    ) {
      formik.setFieldValue("sessionFee", 0);
    }
    if (
      selectedTariffElements.includes(TariffElement.KwhBasedTariff) &&
      !newValue.find((element) => element.value === TariffElement.KwhBasedTariff)
    ) {
      formik.setFieldValue("kwhFee", 0);
    }
    if (
      selectedTariffElements.includes(TariffElement.TimeBasedTariff) &&
      !newValue.find((element) => element.value === TariffElement.TimeBasedTariff)
    ) {
      formik.setFieldValue("chargingHourFee", 0);
      formik.setFieldValue("chargingHourStepSize", 0);
    }
    if (
      selectedTariffElements.includes(TariffElement.IdlingFee) &&
      !newValue.find((element) => element.value === TariffElement.IdlingFee)
    ) {
      formik.setFieldValue("parkingHourFee", 0);
      formik.setFieldValue("parkingHourStepSize", 0);
      formik.setFieldValue("parkingGracePeriodMin", 0);
      formik.setFieldValue("parkingHourFeeLimit", 0);
      formik.setFieldValue("parkingExceptionPeriods", []);
    }
    setSelectedTariffElements(newValue.map((element) => element.value));
  };

  // Initialize selectedTariffElements with elements from formik.values
  useEffect(() => {
    if (action === "edit" && id !== undefined) {
      const filledElements: TariffElement[] = [];
      if (editFormProps.taxDefinition !== TaxDefinition.VAT_UNDEFINED) {
        filledElements.push(TariffElement.VAT);
      }
      if (editFormProps.sessionFee) {
        filledElements.push(TariffElement.ActivationPrice);
      }
      if (editFormProps.kwhFee) {
        filledElements.push(TariffElement.KwhBasedTariff);
      }
      if (editFormProps.chargingHourFee && editFormProps.chargingHourStepSize) {
        filledElements.push(TariffElement.TimeBasedTariff);
      }
      if (editFormProps.parkingHourFee && editFormProps.parkingHourStepSize) {
        filledElements.push(TariffElement.IdlingFee);
      }
      setSelectedTariffElements(filledElements);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editFormProps]);

  return (
    <Page
      title={pageTitle}
      breadcrumbs={[{ title: "tariffs", link: "/tariffs" }, { title: action === "new" ? "addTariff" : "editTariff" }]}
    >
      <EntityEditForm
        title={action === "new" ? "addTariff" : "editTariff"}
        onClose={() => onClose(id ?? editFormProps.tariffId)}
        onSubmit={formik.handleSubmit}
      >
        <Grid item md={7} xs={12}>
          <Box display="flex" flexDirection="column">
            <TextField
              fullWidth
              required
              id="name"
              name="name"
              label={intl.formatMessage({ id: "tariffName" })}
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name && intl.formatMessage({ id: formik.errors.name })}
            />

            <TextField
              fullWidth
              id="description"
              name="description"
              label={intl.formatMessage({ id: "tariffDescr" })}
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.description && Boolean(formik.errors.description)}
              helperText={
                formik.touched.description &&
                formik.errors.description &&
                intl.formatMessage({ id: formik.errors.description })
              }
            />

            <Autocomplete
              disablePortal
              options={currencyCodes}
              isOptionEqualToValue={(option, value) => option.id === value?.id}
              id="currency"
              value={currencyCodesMap.get(formik.values.currency)}
              onChange={(_e, value) => formik.setFieldValue("currency", value?.id)}
              onBlur={formik.handleBlur}
              renderInput={(params) => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  sx={{ marginBottom: 3 }}
                  label={intl.formatMessage({ id: "currency" })}
                  error={formik.touched.currency && Boolean(formik.errors.currency)}
                  helperText={
                    formik.touched.currency &&
                    formik.errors.currency &&
                    intl.formatMessage({ id: formik.errors.currency })
                  }
                />
              )}
            />

            <Autocomplete
              multiple
              id="tariffElementOptions"
              options={tariffElementOptions}
              disableCloseOnSelect
              getOptionLabel={(option) => option.label}
              value={selectedTariffElements.map((element: TariffElement) => ({
                label: element,
                value: element,
              }))}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              onChange={(_e, newValue) => {
                setTariffElemField(newValue);
              }}
              renderOption={(props, option) => (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <li {...props}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selectedTariffElements.includes(option.value)}
                      />
                    }
                    label={option.label}
                  />
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  label="Select Tariff Elements"
                />
              )}
            />
          </Box>

          <ElementBox
            title="vatPercent"
            titleSuffix={
              <FormGroup>
                <Tooltip title={intl.formatMessage({ id: "vatEnabledHelperText" })} placement="bottom">
                  <FormControlLabel
                    control={
                      <Switch
                        id="taxIncluded"
                        size="small"
                        checked={formik.values.taxDefinition === TaxDefinition.VAT_INCLUDED}
                        onChange={(e, checked) =>
                          checked
                            ? formik.setFieldValue("taxDefinition", TaxDefinition.VAT_INCLUDED)
                            : formik.setFieldValue("taxDefinition", TaxDefinition.VAT_EXCLUDED)
                        }
                      />
                    }
                    label={<Typography variant="body2">{intl.formatMessage({ id: "vatIncluded" })}</Typography>}
                    labelPlacement="start"
                  />
                </Tooltip>
              </FormGroup>
            }
            style={{ display: selectedTariffElements.includes(TariffElement.VAT) ? "block" : "none" }}
          >
            <TextField
              fullWidth
              id="taxPercentage"
              name="taxPercentage"
              label={intl.formatMessage({ id: "vatPercentage" })}
              value={formik.values.taxPercentage}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.taxPercentage && Boolean(formik.errors.taxPercentage)}
              helperText={
                formik.touched.taxPercentage &&
                formik.errors.taxPercentage &&
                intl.formatMessage({ id: formik.errors.taxPercentage })
              }
              InputLabelProps={{ shrink: true }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Typography variant="body1">%</Typography>
                  </InputAdornment>
                ),
              }}
            />
          </ElementBox>

          <ElementBox
            title="activationPrice"
            style={{ display: selectedTariffElements.includes(TariffElement.ActivationPrice) ? "block" : "none" }}
          >
            <TextField
              fullWidth
              id="sessionFee"
              name="sessionFee"
              label={intl.formatMessage({ id: "activationPrice" })}
              value={formik.values.sessionFee}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.sessionFee && Boolean(formik.errors.sessionFee)}
              helperText={
                formik.touched.sessionFee &&
                formik.errors.sessionFee &&
                intl.formatMessage({ id: formik.errors.sessionFee })
              }
              InputLabelProps={{ shrink: true }}
            />
          </ElementBox>

          <ElementBox
            title="tariffKwh"
            style={{ display: selectedTariffElements.includes(TariffElement.KwhBasedTariff) ? "block" : "none" }}
          >
            <TextField
              fullWidth
              id="kwhFee"
              name="kwhFee"
              label={intl.formatMessage({ id: "kwhFee" })}
              value={formik.values.kwhFee}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.kwhFee && Boolean(formik.errors.kwhFee)}
              helperText={
                formik.touched.kwhFee && formik.errors.kwhFee && intl.formatMessage({ id: formik.errors.kwhFee })
              }
              InputLabelProps={{ shrink: true }}
            />
          </ElementBox>

          <ElementBox
            title="tariffTime"
            style={{ display: selectedTariffElements.includes(TariffElement.TimeBasedTariff) ? "block" : "none" }}
          >
            <Box display="flex" flexDirection="row">
              <TextField
                fullWidth
                sx={{
                  marginRight: 2,
                }}
                id="chargingHourFee"
                name="chargingHourFee"
                label={intl.formatMessage({ id: "timePrice" })}
                value={formik.values.chargingHourFee}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.chargingHourFee && Boolean(formik.errors.chargingHourFee)}
                helperText={
                  formik.touched.chargingHourFee &&
                  formik.errors.chargingHourFee &&
                  intl.formatMessage({ id: formik.errors.chargingHourFee })
                }
                InputLabelProps={{ shrink: true }}
              />

              <Autocomplete
                fullWidth
                disablePortal
                options={timeBlockOptions}
                isOptionEqualToValue={(option, value) => option.id === value?.id}
                id="chargingHourStepSize"
                value={timeBlockOptions.find((option) => option.id === formik.values.chargingHourStepSize) || null}
                onChange={(_e, value) => formik.setFieldValue("chargingHourStepSize", value?.id)}
                onBlur={formik.handleBlur}
                renderInput={(params) => (
                  <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    sx={{ marginBottom: 3 }}
                    label={intl.formatMessage({ id: "timeBlock" })}
                    error={formik.touched.chargingHourStepSize && Boolean(formik.errors.chargingHourStepSize)}
                    helperText={
                      formik.touched.chargingHourStepSize &&
                      formik.errors.chargingHourStepSize &&
                      intl.formatMessage({ id: formik.errors.chargingHourStepSize })
                    }
                  />
                )}
              />
            </Box>
          </ElementBox>

          <ElementBox
            title="idlingFee"
            style={{ display: selectedTariffElements.includes(TariffElement.IdlingFee) ? "block" : "none" }}
          >
            <Box display="flex" flexDirection="row">
              <TextField
                fullWidth
                sx={{
                  marginRight: 2,
                }}
                id="parkingHourFee"
                name="parkingHourFee"
                label={intl.formatMessage({ id: "timePrice" })}
                value={formik.values.parkingHourFee}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.parkingHourFee && Boolean(formik.errors.parkingHourFee)}
                helperText={
                  formik.touched.parkingHourFee &&
                  formik.errors.parkingHourFee &&
                  intl.formatMessage({ id: formik.errors.parkingHourFee })
                }
                InputLabelProps={{ shrink: true }}
              />

              <Autocomplete
                fullWidth
                disablePortal
                options={timeBlockOptions}
                isOptionEqualToValue={(option, value) => option.id === value?.id}
                id="parkingHourStepSize"
                value={timeBlockOptions.find((option) => option.id === formik.values.parkingHourStepSize) || null}
                onChange={(_e, value) => formik.setFieldValue("parkingHourStepSize", value?.id)}
                onBlur={formik.handleBlur}
                renderInput={(params) => (
                  <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    sx={{ marginBottom: 3 }}
                    label={intl.formatMessage({ id: "timeBlock" })}
                    error={formik.touched.parkingHourStepSize && Boolean(formik.errors.parkingHourStepSize)}
                    helperText={
                      formik.touched.parkingHourStepSize &&
                      formik.errors.parkingHourStepSize &&
                      intl.formatMessage({ id: formik.errors.parkingHourStepSize })
                    }
                  />
                )}
              />
            </Box>

            <TextField
              fullWidth
              id="parkingGracePeriodMin"
              name="parkingGracePeriodMin"
              label={intl.formatMessage({ id: "gracePeriod" })}
              value={formik.values.parkingGracePeriodMin}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.parkingGracePeriodMin && Boolean(formik.errors.parkingGracePeriodMin)}
              helperText={
                formik.touched.parkingGracePeriodMin &&
                formik.errors.parkingGracePeriodMin &&
                intl.formatMessage({ id: formik.errors.parkingGracePeriodMin })
              }
              InputLabelProps={{ shrink: true }}
            />

            <TextField
              fullWidth
              id="parkingHourFeeLimit"
              name="parkingHourFeeLimit"
              label={intl.formatMessage({ id: "maxPrice" })}
              value={formik.values.parkingHourFeeLimit}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.parkingHourFeeLimit && Boolean(formik.errors.parkingHourFeeLimit)}
              helperText={
                formik.touched.parkingHourFeeLimit &&
                formik.errors.parkingHourFeeLimit &&
                intl.formatMessage({ id: formik.errors.parkingHourFeeLimit })
              }
              InputLabelProps={{ shrink: true }}
            />
            <Typography variant="body2">{intl.formatMessage({ id: "maxPriceVatDislaimer" })}</Typography>

            <ParkingException
              values={formik.values}
              errors={formik.errors}
              setFieldValue={formik.setFieldValue}
              setFieldError={formik.setFieldError}
            />
          </ElementBox>
        </Grid>
      </EntityEditForm>
    </Page>
  );
};

export default TariffEdit;
