import { Button, Grid, TextField } from "@mui/material";
import { FC, useState } from "react";
import useDebugStationAction from "src/hooks/debug/useDebugStationAction";
import { notifyErrorRaw } from "../notifications";
import { parseOptionalNumber, parseOptionalString } from "../parse-utilities";

const GetCompositeSchedule: FC<{ stationId: string }> = ({ stationId }) => {
  const [connectorId, setConnectorId] = useState<number>(1);
  const [duration, setDuration] = useState<number | undefined>();
  const [charginRateUnit, setChargingRateUnit] = useState<string | undefined>();
  const { mutate } = useDebugStationAction(stationId);

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        if (stationId)
          mutate({
            method: "POST",
            url: "get_composite_schedule",
            body: {
              connector_id: connectorId,
              charging_rate_unit: charginRateUnit,
              duration,
            },
          });
        else notifyErrorRaw("Station ID is required");
      }}
    >
      <Grid container alignItems="center" spacing={1}>
        <Grid item>
          <TextField
            label="connectorId"
            size="small"
            required
            onChange={(event) => setConnectorId(Number(event.target.value))}
          />
        </Grid>

        <Grid item>
          <TextField
            label="duration"
            size="small"
            onChange={(event) => setDuration(parseOptionalNumber(event.target.value))}
          />
        </Grid>

        <Grid item>
          <TextField
            label="unit"
            size="small"
            onChange={(event) => setChargingRateUnit(parseOptionalString(event.target.value))}
          />
        </Grid>

        <Grid item>
          <Button variant="contained" type="submit">
            GET COMPOSITE SCHEDULE
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default GetCompositeSchedule;
