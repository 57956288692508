import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers-pro";
import dayjs, { Dayjs } from "dayjs";
import { FC, useState } from "react";
import useDebugStationAction from "src/hooks/debug/useDebugStationAction";
import { parseOptionalNumber } from "src/pages/DeveloperTools/parse-utilities";
import { notifyErrorRaw } from "../notifications";

const chargingProfilePurposes = ["ChargePointMaxProfile", "TxDefaultProfile", "TxProfile"];

const chargingProfileKinds = ["Relative", "Absolute"];

const SetChargingProfile: FC<{ stationId: string }> = ({ stationId }) => {
  const [connectorId, setConnectorId] = useState<number>(1);
  const [transactionId, setTransactionId] = useState<number | undefined>();
  const [chargingProfileId, setChargingProfileId] = useState<number>(123);
  const [stackLevel, setStackLevel] = useState<number>(1);
  const [chargingProfilePurpose, setChargingProfilePurpose] = useState<string>("");
  const [chargingProfileKind, setChargingProfileKind] = useState<string>("");
  const [validFrom, setValidFrom] = useState<Dayjs>(dayjs());
  const [validTo, setValidTo] = useState<Dayjs>(dayjs().add(1, "hour"));
  const [startSchedule, setStartSchedule] = useState<Dayjs>(dayjs());
  const [chargingRateUnit, setChargingRateUnit] = useState<string>("");
  const [duration, setDuration] = useState<number>(1);
  const [startPeriod, setStartPeriod] = useState<number>(1);
  const [limit, setLimit] = useState<number>(1);
  const [numberPhases, setNumberPhases] = useState<number>(1);
  const { mutate } = useDebugStationAction(stationId);

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        if (stationId)
          mutate({
            method: "POST",
            url: "custom",
            body: {
              request_type: "SetChargingProfile",
              request_payload: {
                connectorId,
                csChargingProfiles: {
                  chargingProfileId,
                  transactionId,
                  stackLevel,
                  chargingProfilePurpose,
                  chargingProfileKind,
                  validFrom,
                  validTo,
                  chargingSchedule: {
                    startSchedule,
                    chargingRateUnit,
                    duration,
                    chargingSchedulePeriod: [
                      {
                        startPeriod,
                        limit,
                        numberPhases: numberPhases === 3 ? undefined : numberPhases,
                      },
                    ],
                  },
                },
              },
            },
          });
        else notifyErrorRaw("Station ID is required");
      }}
    >
      <Grid container alignItems="center" spacing={1}>
        <Grid item>
          <TextField
            label="connectorId"
            value={connectorId}
            size="small"
            required
            onChange={(event) => {
              const valueToSet = parseOptionalNumber(event.target.value);
              if (valueToSet !== undefined) {
                setConnectorId(valueToSet);
              }
            }}
          />
        </Grid>

        <Grid item>
          <TextField
            label="chargingProfileId"
            value={chargingProfileId}
            size="small"
            required
            onChange={(event) => {
              const valueToSet = parseOptionalNumber(event.target.value);
              if (valueToSet !== undefined) {
                setChargingProfileId(valueToSet);
              }
            }}
          />
        </Grid>

        <Grid item>
          <TextField
            label="transactionId"
            size="small"
            onChange={(event) => setTransactionId(parseOptionalNumber(event.target.value))}
          />
        </Grid>

        <Grid item>
          <TextField
            label="stackLevel"
            size="small"
            required
            value={stackLevel}
            onChange={(event) => {
              const valueToSet = parseOptionalNumber(event.target.value);
              if (valueToSet !== undefined) {
                setStackLevel(valueToSet);
              }
            }}
          />
        </Grid>

        <Grid item xs={4}>
          <FormControl size="small" fullWidth required>
            <InputLabel>chargingProfilePurpose</InputLabel>
            <Select
              label="chargingProfilePurpose"
              value={chargingProfilePurpose}
              onChange={(event) => setChargingProfilePurpose(event.target.value)}
            >
              {chargingProfilePurposes.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={4}>
          <FormControl size="small" fullWidth required>
            <InputLabel>chargingProfileKind</InputLabel>
            <Select
              label="chargingProfileKind"
              value={chargingProfileKind}
              onChange={(event) => setChargingProfileKind(event.target.value)}
            >
              {chargingProfileKinds.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item>
          <DateTimePicker
            label="validFrom"
            views={["year", "month", "day", "hours", "minutes", "seconds"]}
            slotProps={{ textField: { required: true, size: "small" } }}
            value={validFrom}
            onChange={(value) => setValidFrom(value as Dayjs)}
          />
        </Grid>

        <Grid item>
          <DateTimePicker
            label="validTo"
            views={["year", "month", "day", "hours", "minutes", "seconds"]}
            slotProps={{ textField: { required: true, size: "small" } }}
            value={validTo}
            onChange={(value) => setValidTo(value as Dayjs)}
          />
        </Grid>

        <Grid item>
          <DateTimePicker
            label="startSchedule"
            views={["year", "month", "day", "hours", "minutes", "seconds"]}
            slotProps={{ textField: { required: true, size: "small" } }}
            value={startSchedule}
            onChange={(value) => setStartSchedule(value as Dayjs)}
          />
        </Grid>

        <Grid item>
          <TextField
            label="chargingRateUnit"
            size="small"
            required
            value={chargingRateUnit}
            onChange={(event) => setChargingRateUnit(event.target.value)}
          />
        </Grid>

        <Grid item>
          <TextField
            label="duration"
            value={duration}
            size="small"
            required
            onChange={(event) => {
              const valueToSet = parseOptionalNumber(event.target.value);
              if (valueToSet !== undefined) {
                setDuration(valueToSet);
              }
            }}
          />
        </Grid>

        <Grid item>
          <TextField
            label="startPeriod"
            value={startPeriod}
            size="small"
            required
            onChange={(event) => {
              const valueToSet = parseOptionalNumber(event.target.value);
              if (valueToSet !== undefined) {
                setStartPeriod(valueToSet);
              }
            }}
          />
        </Grid>

        <Grid item>
          <TextField
            label="limit"
            value={limit}
            size="small"
            required
            onChange={(event) => {
              const valueToSet = parseOptionalNumber(event.target.value);
              if (valueToSet !== undefined) {
                setLimit(valueToSet);
              }
            }}
          />
        </Grid>

        <Grid item>
          <TextField
            label="numberPhases"
            value={numberPhases}
            size="small"
            required
            onChange={(event) => {
              const valueToSet = parseOptionalNumber(event.target.value);
              if (valueToSet !== undefined) {
                setNumberPhases(valueToSet);
              }
            }}
          />
        </Grid>

        <Grid item>
          <Button variant="contained" type="submit">
            SET CHARGING PROFILE
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default SetChargingProfile;
