import { Button, Grid, TextField } from "@mui/material";
import { FC, useState } from "react";
import useDebugStationAction from "src/hooks/debug/useDebugStationAction";
import { notifyErrorRaw } from "../notifications";
import { parseOptionalNumber, parseOptionalString } from "../parse-utilities";

const RemoteStart: FC<{ stationId: string }> = ({ stationId }) => {
  const [connectorId, setConnectorId] = useState<number>(1);

  const [idTag, setIdTag] = useState<string>("");
  const [limit, setLimit] = useState<number | undefined>();
  const [unit, setUnit] = useState<string | undefined>();
  const { mutate } = useDebugStationAction(stationId);

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        if (stationId)
          mutate({
            method: "POST",
            url: "custom",
            body: {
              request_type: "RemoteStartTransaction",
              request_payload: {
                idTag,
                connectorId,
                chargingProfile:
                  unit !== undefined && unit != null && unit !== "" && limit !== undefined && limit !== null
                    ? {
                        chargingProfileId: 200,
                        stackLevel: 2,
                        chargingProfilePurpose: "TxProfile",
                        chargingProfileKind: "Relative",
                        chargingSchedule: {
                          chargingRateUnit: unit,
                          chargingSchedulePeriod: [
                            {
                              startPeriod: 0,
                              limit,
                            },
                          ],
                        },
                      }
                    : undefined,
              },
            },
          });
        else notifyErrorRaw("Station ID is required");
      }}
    >
      <Grid container alignItems="center" spacing={1}>
        <Grid item>
          <TextField
            label="idTag"
            size="small"
            required
            value={idTag}
            onChange={(event) => setIdTag(event.target.value)}
          />
        </Grid>

        <Grid item>
          <TextField
            label="connectorId"
            size="small"
            required
            value={connectorId}
            onChange={(event) => {
              const valueToSet = parseOptionalNumber(event.target.value);
              if (valueToSet !== undefined) {
                setConnectorId(valueToSet);
              }
            }}
          />
        </Grid>

        <Grid item>
          <TextField
            label="limit"
            size="small"
            value={limit}
            onChange={(event) => setLimit(parseOptionalNumber(event.target.value))}
          />
        </Grid>

        <Grid item>
          <TextField
            label="unit"
            size="small"
            value={unit}
            onChange={(event) => setUnit(parseOptionalString(event.target.value))}
          />
        </Grid>

        <Grid item>
          <Button variant="contained" type="submit">
            REMOTE START
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default RemoteStart;
