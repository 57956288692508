import { Dayjs } from "dayjs";
import { TariffParkingExceptionPeriod, TaxDefinition } from "src/api/tariffs/interfaces";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";

export interface TariffFormValues {
  tariffId: string;
  name: string;
  description: string;
  currency: string;
  taxPercentage: number;
  taxDefinition: TaxDefinition;
  sessionFee: number;
  kwhFee: number;
  chargingHourFee: number;
  chargingHourStepSize: number;
  parkingHourFee: number;
  parkingHourStepSize: number;
  parkingGracePeriodMin: number;
  parkingHourFeeLimit: number;
  parkingExceptionPeriods: Array<TariffFormParkingExceptionPeriod>;
}

export const initialValues = (): TariffFormValues => ({
  tariffId: uuidv4(),
  name: "",
  description: "",
  currency: "SGD",
  taxPercentage: 0,
  taxDefinition: TaxDefinition.VAT_UNDEFINED,
  sessionFee: 0,
  kwhFee: 0,
  chargingHourFee: 0,
  chargingHourStepSize: 0,
  parkingHourFee: 0,
  parkingHourStepSize: 0,
  parkingGracePeriodMin: 0,
  parkingHourFeeLimit: 0,
  parkingExceptionPeriods: [],
});

// https://github.com/jquense/yup/blob/db3a62a52d6ff0248e5d98df307c4a786e7527b8/src/locale.js#L8C9-L8C9
Yup.setLocale({
  mixed: {
    notType: ({ type, originalValue }) => {
      if (type === "number" && Number.isNaN(Number(originalValue)) && type === "number") {
        return "mustBeNumber";
      }
      return "";
    },
  },
});

const validateNumberWithInternationization = (value: unknown, ctx: Yup.TestContext) => {
  const val = Number(value);
  if (Number.isNaN(Number(value))) {
    return ctx.createError({ message: "mustBeNumber" });
  }
  if (val < 0) {
    return ctx.createError({ message: "mustBePositive" }); // using positive() chained to number() always triggers even if field absent, hence rely on custom test().
  }
  return true;
};

const fields = {
  name: Yup.string().required("schemaRequiredTariffName"),
  currency: Yup.string().required("schemaRequiredCurrency"),
  // borrow JSON locale mustBeNumber
  taxPercentage: Yup.number().test({
    skipAbsent: true,
    test(value, ctx) {
      return validateNumberWithInternationization(value, ctx);
    },
  }),
  sessionFee: Yup.number().test({
    skipAbsent: true,
    test(value, ctx) {
      return validateNumberWithInternationization(value, ctx);
    },
  }),
  kwhFee: Yup.number().test({
    skipAbsent: true,
    test(value, ctx) {
      return validateNumberWithInternationization(value, ctx);
    },
  }),
  chargingHourFee: Yup.number().test({
    skipAbsent: true,
    test(value, ctx) {
      return validateNumberWithInternationization(value, ctx);
    },
  }),
  parkingHourFee: Yup.number().test({
    skipAbsent: true,
    test(value, ctx) {
      return validateNumberWithInternationization(value, ctx);
    },
  }),
  parkingGracePeriodMin: Yup.number().test({
    skipAbsent: true,
    test(value, ctx) {
      return validateNumberWithInternationization(value, ctx);
    },
  }),
  parkingHourFeeLimit: Yup.number().test({
    skipAbsent: true,
    test(value, ctx) {
      return validateNumberWithInternationization(value, ctx);
    },
  }),
};

export const validationSchema = Yup.object().shape({
  ...fields,
});

export interface TariffFormParkingExceptionPeriod {
  from: Dayjs;
  to: Dayjs;
}

export const toRequest = (input: Array<TariffFormParkingExceptionPeriod>) => {
  const res: Array<TariffParkingExceptionPeriod> = [];
  input.forEach((el) => {
    res.push({
      from: el.from.format("HH:mm"),
      to: el.to.format("HH:mm"),
    });
  });
  return res;
};
